<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ sectionTitle }}
    </div>
    <el-checkbox
      :model-value="checkValue"
      :label="filter.label"
      @change="handleFilterChange"
    >
      {{ filter.label }}
    </el-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [Boolean, String],
      required: false,
      default: false
    },
    sectionTitle: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  computed: {
    checkValue() {
      if (this.modelValue === true || this.modelValue === 'true') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    handleFilterChange(v) {
      const value = v === false ? null : true
      this.$emit('update:modelValue', value)
    }
  }
}
</script>
